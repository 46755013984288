<svg
	xmlns="http://www.w3.org/2000/svg"
	width="100%"
	height="100%"
	viewBox="0 0 24 24"
	fill="red"
	stroke="red"
	stroke-width="1.5"
	stroke-linecap="round"
	stroke-linejoin="round"
	class="feather feather-square"
>
	<rect x="3" y="3" width="18" height="18" rx="2" ry="2" />
</svg>
