<svg
	xmlns="http://www.w3.org/2000/svg"
	width="100%"
	height="100%"
	viewBox="0 0 24 24"
	fill="none"
	stroke="currentColor"
	stroke-width="1.5"
	stroke-linecap="round"
	stroke-linejoin="round"
	class="feather feather-rotate-ccw"
>
	<polyline points="1 4 1 10 7 10" />
	<path d="M3.51 15a9 9 0 1 0 2.13-9.36L1 10" />
</svg>
